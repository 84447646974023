<template>
  <div>
    <EditorMenuBar v-if="loadingRender" v-slot="{ commands, isActive }" :editor="editor">
      <div class="text-editor__heading">
        <a-tooltip placement="bottom">
          <template #title>
            Bold
          </template>
          <a-button
            icon="bold"
            :class="{
              active: isActive.bold()
            }"
            @click="commands.bold"
          />
        </a-tooltip>

        <a-tooltip placement="bottom">
          <template #title>
            Italic
          </template>
          <a-button
            icon="italic"
            :class="{
              active: isActive.italic()
            }"
            @click="commands.italic"
          />
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template #title>
            strikethrough
          </template>
          <a-button
            icon="strikethrough"
            :class="{
              active: isActive.strike()
            }"
            @click="commands.strike"
          />
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template #title>
            Underline
          </template>
          <a-button
            icon="underline"
            :class="{
              active: isActive.underline()
            }"
            @click="commands.underline"
          />
        </a-tooltip>

        <a-tooltip placement="bottom">
          <template #title>
            Heading 1
          </template>
          <a-button
            class="ml-3 px-2"
            :class="{
              active: isActive.heading({ level: 1 })
            }"
            @click="commands.heading({ level: 1 })"
          >
            H1
          </a-button>
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template #title>
            Heading 2
          </template>
          <a-button
            class="px-2"
            :class="{
              active: isActive.heading({ level: 2 })
            }"
            @click="commands.heading({ level: 2 })"
          >
            H2
          </a-button>
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template #title>
            Heading 3
          </template>
          <a-button
            class="px-2"
            :class="{
              active: isActive.heading({ level: 3 })
            }"
            @click="commands.heading({ level: 3 })"
          >
            H3
          </a-button>
        </a-tooltip>

        <a-tooltip placement="bottom">
          <template #title>
            Order List
          </template>
          <a-button
            icon="ordered-list"
            class="ml-3"
            :class="{
              active: isActive.ordered_list()
            }"
            @click="commands.ordered_list"
          />
        </a-tooltip>

        <a-tooltip placement="bottom">
          <template #title>
            Bullet List
          </template>
          <a-button
            icon="unordered-list"
            :class="{
              active: isActive.bullet_list()
            }"
            @click="commands.bullet_list"
          />
        </a-tooltip>

        <a-tooltip placement="bottom">
          <template #title>
            Undo
          </template>
          <a-button icon="undo" class="ml-3" @click="commands.undo" />
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template #title>
            Redo
          </template>
          <a-button icon="redo" @click="commands.redo" />
        </a-tooltip>
      </div>
    </EditorMenuBar>
    <EditorContent v-if="loadingRender" style="white-space: pre-line;" :editor="editor" class="text-editor__body" />
  </div>
</template>

<script>
// import debounce from 'lodash/debounce'
import { EditorContent, Editor, EditorMenuBar } from 'tiptap'
import {
  Bold,
  Italic,
  HardBreak,
  Underline,
  Heading,
  Blockquote,
  Strike,
  ListItem,
  History,
  OrderedList,
  BulletList,
  Placeholder,
} from 'tiptap-extensions'

export default {
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: {
    placeholder: {
      type: String,
      default() {
        return 'Write something'
      },
    },
    value: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      editorChange: false,
      editor: new Editor({
        // parseOptions: {
        //   preserveWhitespace: 'full',
        // },
        extensions: [
          new Bold(),
          new Heading({
            level: [1, 2, 3],
          }),
          new Italic(),
          new Underline(),
          new Blockquote(),
          new Strike(),
          new ListItem(),
          new History(),
          new HardBreak(),
          new OrderedList(),
          new BulletList(),
          new Placeholder({
            showOnlyCurrent: false,
            emptyNodeText: this.placeholder,
          }),
        ],
        content: this.value,
        onUpdate: ({ getHTML }) => {
          this.emitUpdate(getHTML())
        },
        onBlur: () => { // val | { event, state, view }
          // console.log('onBlur val: ', val)
          // let content = state.getHTML()
          // if (content === '<p></p>') content = ''

          // this.$emit('change', content)
          // this.$emit('change.value', content)

          // console.log('onBlur this.editorValue: ', this.editorValue)
          const parseSchemaTokopediaDescription = this.editorValue.replaceAll('</p>', '</p>\n')
          this.$emit('input', parseSchemaTokopediaDescription)
          this.$emit('change', parseSchemaTokopediaDescription)
        },
      }),
      loadingRender: false,
      editorValue: this.$props.value,
    }
  },
  watch: {
    value: {
      // immediate: true,
      handler(val) {
        this.$nextTick(() => {
          const content = val === '<p></p>' ? '' : val
          if (this.editor && !this.editorChange) {
            this.editor.setContent(content, true)
          }
          this.editorChange = false
        })
      },
    },
  },
  created() {
    // this.emitUpdate = debounce((value) => {
    //   let content = value
    //   if (content === '<p></p>') content = ''

    //   if (this.editorChange) {
    //     this.$emit('input', content)
    //     this.$emit('change', content)
    //   }
      
    //   this.editorChange = true
    // }, 1) // 200

    this.emitUpdate = (value) => {
      let content = value
      if (content === '<p></p>') content = ''

      if (this.editorChange) {
        this.$emit('input', content)
        this.$emit('change', content)
      }
      this.editorValue = content
      this.editorChange = true
    }

    setTimeout(() => {
      this.loadingRender = true
    }, 1)
  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
.text-editor {
  &__heading {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    margin-bottom: 1rem;

    button {
      flex-shrink: 0;
      flex-grow: 0;

      &:not(:last-child) {
      }
    }
  }

  &__body {
    .ProseMirror {
      padding: 1rem;
      height: 250px;
      overflow-y: auto;
      border-radius: 4px;
      border: 1px solid #e4e9f0;
      outline: 0;

      &-focused {
        border-color: #78a3ff;
      }

      p, dl, ol, ul {
        margin-bottom: 0.25rem;
      }

      p.is-editor-empty:first-child::before {
        content: attr(data-empty-text);
        float: left;
        color: #aaa;
        pointer-events: none;
        height: 0;
        font-style: italic;
      }
    }
  }
}
</style>
