<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    value: {
      required: true,
    },
    checkValue: {
      default: () => true,
    },
    uncheckValue: {
      default: () => false,
    },
    disabled: {
      default: () => false,
    },
    label: {
      type: String,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const checked = computed(() => props.value === props.checkValue)
    const unchecked = computed(() => props.value === props.uncheckValue)

    return {
      checked,
      unchecked,
      onChange() {
        emit('input', checked.value ? props.uncheckValue : props.checkValue )
      },
    }
  },
})
</script>

<template>
  <div>
    <label v-if="!disabled">
      <input
        ref="input"
        type="checkbox"
        :checked="checked"
        :data-checked="checked"
        :disabled="disabled"
        @input="onChange"
      />
      <span>{{ $props.label }}</span>
    </label>
    <div v-else>
      <a-checkbox class="mr-2" disabled :checked="checked" />
      <span>{{ $props.label }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
  label {
    position: relative;
    line-height: 1.5 !important;

    input {
      display: none;
    }

    span {
      display: inline-block;
      padding-left: 2rem;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #ccc;
        background: #fff;
        transition: all 0.3s ease-in-out;
      }

      &:after {
        content: '';
        position: absolute;
        left: 8px;
        top: 4px;
        width: 5px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    input:checked + span {
      &::before {
        background: var(--kit-color-primary);
        border-color: var(--kit-color-primary);
      }
    }
  }
</style>
