var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loadingRender)?_c('EditorMenuBar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function({ commands, isActive }){return [_c('div',{staticClass:"text-editor__heading"},[_c('a-tooltip',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Bold ")]},proxy:true}],null,true)},[_c('a-button',{class:{
            active: isActive.bold()
          },attrs:{"icon":"bold"},on:{"click":commands.bold}})],1),_c('a-tooltip',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Italic ")]},proxy:true}],null,true)},[_c('a-button',{class:{
            active: isActive.italic()
          },attrs:{"icon":"italic"},on:{"click":commands.italic}})],1),_c('a-tooltip',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" strikethrough ")]},proxy:true}],null,true)},[_c('a-button',{class:{
            active: isActive.strike()
          },attrs:{"icon":"strikethrough"},on:{"click":commands.strike}})],1),_c('a-tooltip',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Underline ")]},proxy:true}],null,true)},[_c('a-button',{class:{
            active: isActive.underline()
          },attrs:{"icon":"underline"},on:{"click":commands.underline}})],1),_c('a-tooltip',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Heading 1 ")]},proxy:true}],null,true)},[_c('a-button',{staticClass:"ml-3 px-2",class:{
            active: isActive.heading({ level: 1 })
          },on:{"click":function($event){return commands.heading({ level: 1 })}}},[_vm._v(" H1 ")])],1),_c('a-tooltip',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Heading 2 ")]},proxy:true}],null,true)},[_c('a-button',{staticClass:"px-2",class:{
            active: isActive.heading({ level: 2 })
          },on:{"click":function($event){return commands.heading({ level: 2 })}}},[_vm._v(" H2 ")])],1),_c('a-tooltip',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Heading 3 ")]},proxy:true}],null,true)},[_c('a-button',{staticClass:"px-2",class:{
            active: isActive.heading({ level: 3 })
          },on:{"click":function($event){return commands.heading({ level: 3 })}}},[_vm._v(" H3 ")])],1),_c('a-tooltip',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Order List ")]},proxy:true}],null,true)},[_c('a-button',{staticClass:"ml-3",class:{
            active: isActive.ordered_list()
          },attrs:{"icon":"ordered-list"},on:{"click":commands.ordered_list}})],1),_c('a-tooltip',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Bullet List ")]},proxy:true}],null,true)},[_c('a-button',{class:{
            active: isActive.bullet_list()
          },attrs:{"icon":"unordered-list"},on:{"click":commands.bullet_list}})],1),_c('a-tooltip',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Undo ")]},proxy:true}],null,true)},[_c('a-button',{staticClass:"ml-3",attrs:{"icon":"undo"},on:{"click":commands.undo}})],1),_c('a-tooltip',{attrs:{"placement":"bottom"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Redo ")]},proxy:true}],null,true)},[_c('a-button',{attrs:{"icon":"redo"},on:{"click":commands.redo}})],1)],1)]}}],null,false,2031722214)}):_vm._e(),(_vm.loadingRender)?_c('EditorContent',{staticClass:"text-editor__body",staticStyle:{"white-space":"pre-line"},attrs:{"editor":_vm.editor}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }