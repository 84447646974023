<template>
  <ValidationProvider
    v-slot="slotProps"
    :vid="vid"
    :name="name || label"
    :rules="rules"
    :tag="tag"
    v-bind="$attrs"
  >
    <a-form-item
      :label="label"
      :has-feedback="!!slotProps.errors.length"
      :validate-status="resolveState(slotProps)"
      :help="slotProps.errors[0]"
    >
      <slot v-bind="slotProps" />
    </a-form-item>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
      default: () => undefined,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    tag: String,
    label: String,
    name: String,
  },
  methods: {
    resolveState({ errors, pending, valid }) {
      if (errors[0]) {
        return 'error'
      }

      if (pending) {
        return 'validating'
      }

      if (valid) {
        return 'success'
      }

      return ''
    },
  },
}
</script>
